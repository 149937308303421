import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { gsap } from 'gsap';
import useTypedSelector from '@/hooks/useTypedSelector';

const AccountFooter: React.FC = (): React.ReactElement => {
  const router = useRouter();
  const listRef = useRef<any>(null);
  const { role, tariff_days } = useTypedSelector((state) => state.user);

  useEffect(() => {
    if (role === 'user') {
      if (router.pathname === '/account') {
        gsap.to(listRef.current.children[0], {
          duration: 0.4,
          opacity: 1,

          onStart: () => {
            gsap.to(listRef.current.children[0].children[1], {
              duration: 0.4,
              textShadow: '0px 0px #ffffff80'
            });
          }
        });
      } else {
        gsap.to(listRef.current.children[0], {
          duration: 0.4,
          opacity: 0.5,

          onStart: () => {
            gsap.to(listRef.current.children[0].children[1], {
              duration: 0.4,
              textShadow: 'none'
            });
          }
        });
      }
      if (router.pathname === '/account/dialogs') {
        gsap.to(listRef.current.children[1], {
          duration: 0.4,
          opacity: 1,

          onStart: () => {
            gsap.to(listRef.current.children[1].children[1], {
              duration: 0.4,
              textShadow: '0px 0px #ffffff80'
            });
          }
        });
      } else {
        gsap.to(listRef.current.children[1], {
          duration: 0.4,
          opacity: 0.5,

          onStart: () => {
            gsap.to(listRef.current.children[1].children[1], {
              duration: 0.4,
              textShadow: 'none'
            });
          }
        });
      }
      if (router.pathname === '/account/settings') {
        gsap.to(listRef.current.children[2], {
          duration: 0.4,
          opacity: 1,

          onStart: () => {
            gsap.to(listRef.current.children[2].children[1], {
              duration: 0.4,
              textShadow: '0px 0px #ffffff80'
            });
          }
        });
      } else {
        gsap.to(listRef.current.children[2], {
          duration: 0.4,
          opacity: 0.5,

          onStart: () => {
            gsap.to(listRef.current.children[2].children[1], {
              duration: 0.4,
              textShadow: 'none'
            });
          }
        });
      }
    }
    if (role === 'coach') {
      if (router.pathname === '/coach-account') {
        gsap.to(listRef.current.children[0], {
          duration: 0.4,
          opacity: 1,

          onStart: () => {
            gsap.to(listRef.current.children[0].children[1], {
              duration: 0.4,
              textShadow: '0px 0px #ffffff80'
            });
          }
        });
      } else {
        gsap.to(listRef.current.children[0], {
          duration: 0.4,
          opacity: 0.5,

          onStart: () => {
            gsap.to(listRef.current.children[0].children[1], {
              duration: 0.4,
              textShadow: 'none'
            });
          }
        });
      }
      if (router.pathname === '/coach-account/dialogs') {
        gsap.to(listRef.current.children[1], {
          duration: 0.4,
          opacity: 1,

          onStart: () => {
            gsap.to(listRef.current.children[1].children[1], {
              duration: 0.4,
              textShadow: '0px 0px #ffffff80'
            });
          }
        });
      } else {
        gsap.to(listRef.current.children[1], {
          duration: 0.4,
          opacity: 0.5,

          onStart: () => {
            gsap.to(listRef.current.children[1].children[1], {
              duration: 0.4,
              textShadow: 'none'
            });
          }
        });
      }
      if (router.pathname === '/coach-account/clients') {
        gsap.to(listRef.current.children[2], {
          duration: 0.4,
          opacity: 1,

          onStart: () => {
            gsap.to(listRef.current.children[2].children[1], {
              duration: 0.4,
              textShadow: '0px 0px #ffffff80'
            });
          }
        });
      } else {
        gsap.to(listRef.current.children[2], {
          duration: 0.4,
          opacity: 0.5,

          onStart: () => {
            gsap.to(listRef.current.children[2].children[1], {
              duration: 0.4,
              textShadow: 'none'
            });
          }
        });
      }
      if (router.pathname === '/coach-account/settings') {
        gsap.to(listRef.current.children[3], {
          duration: 0.4,
          opacity: 1,

          onStart: () => {
            gsap.to(listRef.current.children[2].children[1], {
              duration: 0.4,
              textShadow: '0px 0px #ffffff80'
            });
          }
        });
      } else {
        gsap.to(listRef.current.children[3], {
          duration: 0.4,
          opacity: 0.5,

          onStart: () => {
            gsap.to(listRef.current.children[3].children[1], {
              duration: 0.4,
              textShadow: 'none'
            });
          }
        });
      }
    }
  }, [router, role]);

  const handleClick = (e: any, link: any): void => {
    e.preventDefault();

    router.push(link);
  };

  return (
    <AccountFooterWrapper>
      <AccountFooterNavigation>
        <AccountFooterList ref={listRef}>
          <AccountFooterListItem
            id={'0'}
            onClick={(e: any) =>
              handleClick(e, role === 'user' ? '/account' : '/coach-account')
            }
          >
            <AccountFooterItemImage src={'/assets/img/footerLogo/union.svg'} />
            <AccountFooterItemText>Профиль</AccountFooterItemText>
          </AccountFooterListItem>
          <AccountFooterListItem
            id={'1'}
            onClick={(e: any) =>
              handleClick(
                e,
                role === 'user'
                  ? tariff_days !== 0
                    ? '/account/dialogs'
                    : false
                  : '/coach-account/dialogs'
              )
            }
          >
            <AccountFooterItemImage
              src={'/assets/img/footerLogo/footerLogo2.svg'}
            />
            <AccountFooterItemText>Чат</AccountFooterItemText>
          </AccountFooterListItem>
          {role === 'coach' ? (
            <AccountFooterListItem
              id={'2'}
              onClick={(e: any) => handleClick(e, '/coach-account/clients')}
            >
              <AccountFooterItemImage
                src={'/assets/img/footerLogo/clients.svg'}
              />
              <AccountFooterItemText>Клиенты</AccountFooterItemText>
            </AccountFooterListItem>
          ) : null}
          <AccountFooterListItem
            id={'3'}
            onClick={(e: any) =>
              handleClick(
                e,
                role === 'user'
                  ? '/account/settings'
                  : '/coach-account/settings'
              )
            }
          >
            <AccountFooterItemImage
              src={'/assets/img/footerLogo/footerLogo3.svg'}
            />
            <AccountFooterItemText>Настройки</AccountFooterItemText>
          </AccountFooterListItem>
        </AccountFooterList>
      </AccountFooterNavigation>
    </AccountFooterWrapper>
  );
};

const AccountFooterWrapper = styled.footer`
    display: flex;
    width: 100%;
    padding: 8px 0 4px 0;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    position: fixed;
    bottom: 0;
    z-index: 100;
`;

const AccountFooterNavigation = styled.nav`
    display: flex;
    width: 100%;
`;

const AccountFooterList = styled.ul`
    display: flex;
    justify-content: space-around;
    width: 100%;
`;

const AccountFooterListItem = styled.li`
    display: flex;
    min-width: 53px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.5;
    position: relative;
`;

const AccountFooterItemImage = styled.img`
    display: block;
    margin: 0 0 5px 0;
    pointer-events: none;
`;

const AccountFooterItemText = styled.span`
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    pointer-events: none;
`;

export default AccountFooter;
