import * as React from 'react';
import styled from 'styled-components';
import { AccountCoachProps } from '@/store/Coaches/types';

const UserCoach: React.FC<AccountCoachProps> = ({
  coach,
}): React.ReactElement => {
  return (
    <UserCoachWrapper>
      <UserCoachImageWrapper>
        {coach?.user.avatar && <UserCoachImage src={coach.user.avatar} />}
      </UserCoachImageWrapper>
      <UserCoachInfo>
        <UserCoachName>
          {coach?.user.name} {coach?.user.surname}
        </UserCoachName>
        <UserCoachSubTitle>ваш тренер</UserCoachSubTitle>
      </UserCoachInfo>
    </UserCoachWrapper>
  );
};

const UserCoachWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 40px 0;
  padding: 0 23px;
`;

const UserCoachImageWrapper = styled.div`
    display: flex;
    width: 64px;
    height: 64px;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    margin: 0 18px 0 0;

    @media (min-width: 390px) and (max-width: 575px) {
    }

    @media (min-width: 576px) and (max-width: 767px) {
        width: 84px;
        height: 84px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
`;

const UserCoachImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const UserCoachInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserCoachName = styled.h3`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.32px;
    margin: 0 0 3px 0;

    @media (min-width: 390px) and (max-width: 575px) {
    }

    @media (min-width: 576px) and (max-width: 767px) {
        font-size: 19px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
`;

const UserCoachSubTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.32px;
  color: #00f7e9;
`;

export default UserCoach;
