import React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import FlexContainer from '@/components/ui/FlexContainer';

const UserAchieve: React.FC = (): React.ReactElement => {
  const router = useRouter();

  const handleClickAchieveItem = (path: string) => {
    router.push(`/account/${path}`);
  };

  return (
    <UserAchieveWrapper>
      <UserAchieveList>
        <UserAchieveItem onClick={() => handleClickAchieveItem('weightDynamics')}>
          <UserAchieveImage src={'/assets/img/account/achieve1.png'} />
          <UserAchieveItemText>Динамика веса</UserAchieveItemText>
        </UserAchieveItem>
        <UserAchieveItem onClick={() => handleClickAchieveItem('bodySize')}>
          <UserAchieveImage src={'/assets/img/account/achieve2.png'} />
          <UserAchieveItemText>Замеры тела</UserAchieveItemText>
        </UserAchieveItem>
        <UserAchieveItem onClick={() => handleClickAchieveItem('media')}>
          <UserAchieveImage src={'/assets/img/account/achieve4.png'} />
          <UserAchieveItemText>Фото и видео</UserAchieveItemText>
        </UserAchieveItem>
      </UserAchieveList>
    </UserAchieveWrapper>
  );
};

export const UserAchieveWrapper = styled(FlexContainer)`
    justify-content: center;
    margin: 0 0 56px 0;
    padding: 0 5px;
    position: relative;

    @media (min-width: 390px) and (max-width: 575px) {
        padding: 0 23px;
    }

    @media (min-width: 576px) and (max-width: 767px) {
        padding: 0 23px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
`;

export const UserAchieveList = styled.ul`
    max-width: 320px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @media (min-width: 390px) and (max-width: 575px) {
        max-width: 340px;
        gap: 20px;
    }

    @media (min-width: 576px) and (max-width: 767px) {
        max-width: 380px;
        gap: 20px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
`;

export const UserAchieveItem = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
    border-radius: 16px;
    padding: 12px 20px 20px 20px;
    cursor: pointer;
    
    @media (min-width: 390px) and (max-width: 575px) {
        width: 160px;
        height: 152px;
    }

    @media (min-width: 576px) and (max-width: 767px) {
        width: 180px;
        height: 162px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
`;

export const UserAchieveImage = styled.img`
    display: flex;
    width: 83px;
    height: 83px;
    border-radius: 50%;
    margin: 0 0 18px 0;
`;

export const UserAchieveItemText = styled.p`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.8px;
    white-space: nowrap;
`;

export default UserAchieve;
