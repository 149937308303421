import styled from 'styled-components';

const TitleH2 = styled.h2`
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 35px 0;
    padding: 0 23px;
    transition: font-size 0.2s ease-in-out;


    @media (max-width: 575px) {
        font-size: 18px;
    }

    @media (min-width: 576px) and (max-width: 767px) {
        font-size: 23px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
`;

export default TitleH2;
